import { async } from "rxjs";
import { fetch } from "../../utils";
import * as moment from 'moment-timezone'

const HostName = process.env.REACT_APP_API_HOSTNAME;
const token = localStorage.getItem('token')

export const logins = async (value) => {
  return fetch(`${HostName}/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",

    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const user = async (value) => {
  return fetch(`${HostName}/user/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
//////////////////////////////////////////////////
export const updateUser = async (id, data) => {
  return fetch(`${HostName}/user/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

///////////////////////////////////////////////////

export const trainingVideo = async (value) => {
  return fetch(`${HostName}/training-video`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const quiz = async (value) => {
  return fetch(`${HostName}/quiz`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const submit = async (value) => {
  return fetch(`${HostName}/user/${value.userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const otp = async (value) => {
  // console.log(value, "api.js")
  return fetch(`${HostName}/user/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyotp = async (value) => {
  return fetch(`${HostName}/user/verifyOTP`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify({
      value
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const signup = async (value) => {
  // console.log({ value })
  return fetch(`${HostName}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(
      value
    ),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotpassw = async (value) => {
  return fetch(`${HostName}/user/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",

    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const newpassword = async (value) => {
  return fetch(`${HostName}/user/${value.id}/${value.token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",

    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


export const editprofile = async (value) => {
  // console.log("value=======api", value)
  var formData = new FormData();
  formData.append('file', value?.user?.file)
  // formData.append('fullname', value?.user?.fullname)
  formData.append('phone', value?.user?.phone)
  // formData.append('email', value?.user?.email)
  formData.append('address', value?.user?.address)
  return fetch(`${HostName}/user/${value.userId}`, {
    method: "PUT",
    headers: {

      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: formData
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};















// export const logins = (value) => {
//   console.log("value=========",value);
//   return  axios.post(`https://340f-150-129-144-58.in.ngrok.io/user/login`, {email:value?.email,password:value?.password})


// };


export const getlots = async (city) => {
  return fetch(`${HostName}/lot?city=${city}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      console.log(res, "resoooo")
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


/////////////////////////////////////////////////
export const getLotById = async (id) => {
  return fetch(`${HostName}/lot/${id}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      // console.log(res.json(),"ressssss")
      return res.json();
    })
    .then((payload) => {
      // console.log(payload,"resss")
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
//////////////////////////////////////////////

/**
 * CREATE CONNECT ACCOUNT OF STRIPE
 */
export const connectWithStripe = async value => {
  // console.log(value, "><<value")
  return fetch(`${HostName}/connectToStripe?code=${value?.code}&userId=${value?.userId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const connectWithStripeExpress = async value => {
  return fetch(`${HostName}/connectToStripe/express?userId=${value?.userId}&is_market_place=true&type=${value?.type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};



export const onboardTaggrOnStripe = async (value, stripeAccount) => {
  return fetch(`${HostName}/connectToStripe/taggr?connecting=${value}&stripeAccount=${stripeAccount}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': `Bearer ${token}`
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


/*checkin */

export const checkin = async value => {
  return fetch(`${HostName}/checkin/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};


/*contact suport */

export const contactsupport = async value => {
  return fetch(`${HostName}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(value),
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// CREATE CITATION
export const createCitation = async value => {
  var formData = new FormData();
  formData.append('lotId', value?.lotId)
  formData.append('tireTagSerialNumber', value?.tireTagSerialNumber)
  formData.append('vehicle[licensePlate]', value?.licensePlate)
  formData.append('state', value?.state)
  formData.append('vehicle[make]', value?.make)
  formData.append('vehicle[model]', value?.model)
  formData.append('vehicle[color]', value?.color)
  formData.append('remark', value?.remark)
  formData.append('howAttached', value?.howAttached)
  formData.append('offenceId', value?.offenceId)
  formData.append('licensePlateImage', value?.licensePlateImage)
  formData.append('signageImage', value?.signageImage)
  formData.append('windowDecalImage', value?.windowDecalImage)
  formData.append('tireTagWheelImage', value?.tireTagWheelImage)
  value?.optionalImage.map((item)=>formData.append('optionalImage', item));
  //// comment for aws amplify

  return fetch(`${HostName}/citation/create`, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
    body: formData,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

export const createEnforcementCitation = async value => {
  var formData = new FormData();
  formData.append('lotId', value?.lotId)
  formData.append('offenceId', value?.offenceId)
  formData.append('vehicle[licensePlate]', value?.license_plate_number)
  formData.append('state', value?.license_plate_state?.name)
  formData.append('license_plate_state_id', value?.license_plate_state?.id)
  formData.append('car_color_id', value?.car_color?.id)
  formData.append('car_make_id', value?.car_make?.id)
  formData.append('code', value?.code)
  formData.append('vehicle[make]', value?.car_make?.name)
  // formData.append('vehicle[model]', value?.car_make?.name)
  formData.append('vehicle[color]', value?.car_color?.name)

  return fetch(`${HostName}/citation/create_enforcement`, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
    body: formData,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};

// GET TIRE TAG SERIAL NUMBER BY OPERATOR
export const getTireTagSerialNumbers = async (lotId) => {
  return fetch(`${HostName}/tiretag-status?lotId=${lotId}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


// GET TIRE TAG SERIAL NUMBER BY OPERATOR
export const updateTireTagStatus = async (tiretag_id, value) => {
  let formData = new FormData();
  formData.append('tagStatus', value?.tagStatus)
  formData.append('tagImage', value?.tagImage)
  
  return fetch(`${HostName}/tiretag-status/update/${tiretag_id}`, {
    method: "PATCH",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
    body: formData
  })
  .then((res) => {
    return res.json();
  })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
  };
  

// END SESSION 
export const end_session = async (lotId, value) => {
  return fetch(`${HostName}/checkin/end_session?id=${lotId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value)
  })
  .then((res) => {
    return res.json();
  })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
  };
  
  
  
  /**
   * CREATE PASSWORD FOR A USER
  */
 export const createPasswordForTaggr = async (value) => {
   return fetch(`${HostName}/user/taggr/createpassword`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
    body: JSON.stringify(value)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => { throw error });
};


// GET TIRE TAG activation number by tire tag id
export const getTireTagActvationNumber = async (tagId) => {
  return fetch(`${HostName}/activation-code?tiretagId=${tagId}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};



export const myCitationinfo = async (value) => {
  return fetch(`${HostName}/citation/taggr/all?page=${value?.page}&size=${value?.size}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};


export const get_license_plate_status = async (value) => {
  return fetch(`${HostName}/license-plate/status?license_plate=${value?.license_plate}&lotId=${value?.lotId}&timezone=${moment.tz.guess()}`, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchCitations = async (searchType, query) => {
  return fetch(`${HostName}/search/operator?type=${searchType}&query=${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


//STATISTICS OF DASHBOARD

export const statistics_taggr = async () => {
  return fetch(`${HostName}/statistics/taggr`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      console.log(res, "resoooo")
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const payoutReconciliation = async ({page, size}) => {
  return fetch(`${HostName}/statistics/payout/taggr?page=${page}&limit=${size}`, {
    method: "GET",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      "access-control-allow-origin": "*"
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const Mail_In = async (value) => {
  const enforcement_url = process.env.REACT_APP_HOST == 'DEVELOPMENT' ? 'http://localhost:3004' : 'https://enforcement.dock.bot';
  return fetch(`${enforcement_url}/auth/tagger`, {
    method: "POST",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
      "access-control-allow-origin": "*"
    },
    body: JSON.stringify(value)
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSurveyByUser = async (lotId) => {
  return fetch(`${HostName}/survey/user?lotId=${lotId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}

export const getEnforcementDetails = async () => {
  return fetch(`https://us-central1-violations-managers.cloudfunctions.net/api/violations/reference-data`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      'api-key': process.env.REACT_APP_ENFORCEMENT_CREATION_API,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
}


export const uploadImage = async (value) => {
  console.log(value)
  var formData = new FormData()
  formData.append('image', value)
  
  return fetch(`${HostName}/citation/image-upload`, {
    method: "POST",
    headers: {
      "access-control-allow-origin": "*",
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: formData
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => { throw error });
};
